import React from 'react'
import tw from 'twin.macro'

import Logo from 'components/logo'

import NavLink from './navlink'

const DesktopHeader = ({ navLinks = [], marginBottom = true, ...props }) => {
  return (
    <header
      css={[
        tw`bg-primary-200 py-2 px-4 border-b-12 border-primary-800`,
        marginBottom && tw`mb-4`,
      ]}
    >
      <div className="container mx-auto flex flex-row items-center justify-between">
        <NavLink to="/">
          <Logo className="w-20" />
        </NavLink>

        <nav className="flex flex-row">
          {navLinks.map((navLink, index) => (
            <div key={index}>{navLink}</div>
          ))}
        </nav>
      </div>
    </header>
  )
}

export default DesktopHeader
