import React from 'react'

import Logo from 'components/logo'
import ABLogo from 'static/images/logos/ab.png'
import AEILogo from 'static/images/logos/aei.png'
import ASGLogo from 'static/images/logos/asg.png'

const Footer = () => {
  return (
    <footer id="footer" className="mt-4 md:mt-10 flex flex-col items-center">
      <div className="flex flex-col items-center px-2">
        <Logo className="w-40 md:w-64" />
        <p className="font-serif text-2xl font-bold">LITTLE SCHOLAR</p>
        <div className="text-center">
          <p>10 N. Penn Avenue, Greenville, Pennsylvania, 16125 | USA</p>
          <p>
            <a href="tel:+17248851136">P. +1 724.885.1136</a>{' '}
            <span className="hidden sm:inline">| </span>
            <br className="sm:hidden" />
            <a href="+17246385370">F. +1 724.638.5370</a>
          </p>
        </div>

        <p className="font-light my-6 text-center">
          © Little Scholar is the intellectual property of American
          Baccalaureate 2020
        </p>
      </div>

      <div className="w-full bg-primary-800 py-2 text-white text-center">
        LITTLE SCHOLAR IS A DIVISION OF
      </div>

      <div className="flex flex-row py-4 h-20 md:h-40 gap-6 items-center space-x-4 ">
        <img className="h-full" src={ABLogo} alt="American Baccalaureate" />
        <img
          className="h-full"
          src={AEILogo}
          alt="American English Institute"
        />
        <img className="h-full" src={ASGLogo} alt="American Scholar Group" />
      </div>
    </footer>
  )
}

export default Footer
