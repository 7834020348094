import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'

const NavLink = styled(Link)(() => [
  tw`flex flex-row items-center mr-2 last:mr-0 px-3 py-1 rounded-md border border-solid border-transparent font-bold text-primary-700`,
  `
    &.active {
      border-bottom: 1px solid white;
    }
  `,
])

export default NavLink
