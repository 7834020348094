import React, { useState } from 'react'
import { Link } from 'gatsby'
import { RiMenuLine, RiCloseLine } from 'react-icons/ri'
import tw from 'twin.macro'

import Logo from 'components/logo'

export default ({ marginBottom = true, navLinks = [] }) => {
  const [showMenu, setShowMenu] = useState(false)

  const handleOpenMenuButtonClick = event => setShowMenu(true)

  const handleCloseMenuButtonClick = event => setShowMenu(false)

  return (
    <header
      id="header-mobile"
      css={[
        tw`relative flex flex-row items-center bg-primary-200 py-2 px-4`,
        marginBottom && tw`mb-4`,
      ]}
    >
      <div className="flex-1">
        <Link to="/">
          <Logo className="h-10" />
        </Link>
      </div>

      <button onClick={handleOpenMenuButtonClick}>
        <RiMenuLine className="text-primary-800" size={24} />
      </button>

      {showMenu && (
        <nav className="fixed flex flex-col top-0 bottom-0 right-0 w-screen p-6 z-50 bg-black bg-opacity-95 text-2xl overflow-hidden">
          <button className="self-end p-2" onClick={handleCloseMenuButtonClick}>
            <RiCloseLine className="text-gray-100" size={24} />
          </button>

          {navLinks.map((navLink, index) => (
            <div key={index}>{navLink}</div>
          ))}
        </nav>
      )}
    </header>
  )
}
