import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'

import Header from 'components/header'
import Footer from 'components/footer'

const Layout = ({
  children,
  fullWidth = false,
  title,
  marginBottom = true,
}) => {
  return (
    <div className="min-h-screen flex flex-col overflow-x-hidden">
      <Header marginBottom={marginBottom} />
      <main css={[tw`flex-1`, !fullWidth && tw`container mx-auto`]}>
        {title && (
          <h1 className="text-center my-4 md:my-8 text-5xl md:text-6xl">
            {title}
          </h1>
        )}
        {children}
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  title: PropTypes.string,
  marginBottom: PropTypes.bool,
}

export default Layout
