import React from 'react'
import PropTypes from 'prop-types'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import MobileHeader from './header-mobile'
import DesktopHeader from './header-desktop'
import NavLink from './navlink'

const Header = ({ marginBottom = true }) => {
  const breakpoints = useBreakpoint()

  const links = [
    <NavLink to="/">HOME</NavLink>,
    <NavLink to="/about">ABOUT</NavLink>,
    <NavLink to="/program">OUR PROGRAM</NavLink>,
    <NavLink to="/advantage">ADVANTAGE</NavLink>,
    <NavLink to="/franchise">FRANCHISE</NavLink>,
    <NavLink to="/information">INFORMATION</NavLink>,
    <NavLink to="/contact">CONTACT</NavLink>,
    // <NavLink to="mailto:info@littlescholar.education">CONTACT</NavLink>,
    // <a
    //   className="flex flex-row items-center mr-2 px-3 py-1 rounded-md border border-solid border-transparent font-bold text-primary-500"
    //   href="mailto:info@littlescholar.education"
    // >
    //   CONTACT
    // </a>,
  ]

  if (breakpoints.lg) {
    return <DesktopHeader marginBottom={marginBottom} navLinks={links} />
  } else {
    return <MobileHeader marginBottom={marginBottom} navLinks={links} />
  }
}

Header.propTypes = {
  marginBottom: PropTypes.bool,
}

Header.defaultProps = {
  marginBottom: true,
}

export default Header
